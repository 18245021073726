import { useMemo } from 'react';

import { Box } from 'components/Box/Box';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { LegacyTooltip } from 'components/LegacyTooltip/LegacyTooltip';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { ApiOrgType } from 'modules/listing/api/org/types/ApiOrgType';
import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';
import { colors } from 'theme/theme';
import { ACTION_TYPE } from 'utils/constants/general/actionType';
import { LISTING_TYPE_HUMAN_NAME_MAP_TITLE } from 'utils/constants/general/listingTypeHumanNameMapTitle';
import { ORG_TYPES } from 'utils/constants/general/orgTypes';

import { getListingTypeLabelIcon } from './getListingTypeLabelIcon';

type Props = {
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT' | 'IMPORTED' | 'ORG';
  actionType?: ApiActionType | null | undefined;
  orgType?: ApiOrgType;
};

export function ListingTypeLabel({ listingType, actionType, orgType }: Props) {
  const icon = getListingTypeLabelIcon({
    listingType,
    actionType: actionType || undefined,
  });

  const orgTypeIcon: IconName | undefined = useMemo(() => {
    if (!orgType) return undefined;

    switch (orgType) {
      case 'NONPROFIT':
        return 'hand';
      case 'CONSULTANT':
        return 'percentage-award';
      case 'RECRUITER':
        return 'person-add';
      case 'CORPORATION':
        return 'mountain-flag';
      case 'GOVERNMENT':
        return 'government';
      case 'COMMUNITY':
        return 'group';
    }
  }, [orgType]);

  const actionTypeObject = ACTION_TYPE.find(({ id }) => actionType === id);

  const orgTypeTitle = useMemo(() => {
    if (!orgType) return undefined;

    const match = ORG_TYPES.find((org) => org.id === orgType);
    return match ? match.name : LISTING_TYPE_HUMAN_NAME_MAP_TITLE.ORG;
  }, [orgType]);

  const content = useMemo(() => {
    if (['VOLOP', 'IMPORTED'].includes(listingType) && actionTypeObject)
      return `${actionTypeObject.name}`;

    if (orgTypeTitle) return orgTypeTitle;

    // TODO: check if we can remove the IMPORTED listing type
    // if we can, add it to the translation list
    if (listingType === 'IMPORTED') return 'imported';

    return LISTING_TYPE_HUMAN_NAME_MAP_TITLE[listingType];
  }, [actionTypeObject, listingType, orgTypeTitle]);

  const tooltip = useMemo(() => {
    if (
      !orgType ||
      (orgType && !['CORPORATION', 'COMMUNITY'].includes(orgType))
    )
      return null;

    const orgTypeObject = ORG_TYPES.find((o) => o.id === orgType);

    if (orgTypeObject) {
      return 'description' in orgTypeObject
        ? orgTypeObject.description
        : undefined;
    }

    throw new Error('orgType not found in ORG_TYPES');
  }, [orgType]);

  return (
    <Box display="flex" alignItems="center">
      <Box mr="8px" color={colors.lightContentGrey} display="inline-flex">
        <Icon name={orgTypeIcon || icon} size={19} />
      </Box>

      <HeadingSmall
        data-qa-id="listing-type"
        my={0}
        color={colors.lightContentGrey}
      >
        {content}
      </HeadingSmall>

      {tooltip && (
        <Box ml="4px" mt="-1px">
          <LegacyTooltip qaId="listing-type-tooltip">{tooltip}</LegacyTooltip>
        </Box>
      )}
    </Box>
  );
}
