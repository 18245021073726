import { ComponentType, MouseEventHandler, ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Box } from 'components/Box/Box';
import { colors, fontWeights } from 'theme/theme';

const TOOLTIP_TRIANGLE_WIDTH = 16;

export const defaultButtonStyles = css<{
  $lightColor?: boolean;
  $greyColor?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  box-shadow: 0 0 0 1px currentcolor inset;
  border-radius: 50%;
  color: ${colors.brandBlue};
  font-size: 10px;
  font-weight: ${fontWeights.semiBold};
  transition: all 0.3s ease;

  &::after {
    content: '?';
  }
  ${(props) =>
    props.$lightColor &&
    `
    color: white;
  `}
  ${(props) =>
    props.$greyColor &&
    `
    color: ${colors.lightContentGrey};
  `}
`;

// @NOTE - Needs to be a div because
// the `LegacyTooltip` is wrapped in
// a `button`, and we don't want
// nested buttons
export const DefaultButton = styled.div<{
  $lightColor?: boolean;
  $greyColor?: boolean;
}>`
  ${defaultButtonStyles}
`;

export const IconButton = styled(Box)`
  color: ${colors.brandBlue};
`;

export const TooltipContainer = styled.div<{
  $right: number;
  $green: boolean;
  $buttonWidth: number;
  $offset: number;
  $width: number;
}>`
  position: absolute;
  right: ${(props) => -1 * props.$offset}px;
  z-index: 103;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    z-index: 1;
    width: ${(props) => props.$width}px;
    height: 16px;
    top: -21px;
    right: ${(props) => props.$right}px;
    background-image: linear-gradient(
      to left,
      ${colors.skyBlue},
      ${colors.hoverBlue}
    );
    ${(props) =>
      `clip-path: polygon(calc(${props.$width - props.$offset}px - ${
        TOOLTIP_TRIANGLE_WIDTH / 2
      }px + ${props.$right}px - ${props.$buttonWidth / 2}px) 0, calc(${
        props.$width - props.$offset
      }px + ${props.$right}px - ${props.$buttonWidth / 2}px) 100%, calc(${
        props.$width - props.$offset
      }px + ${props.$right}px + ${TOOLTIP_TRIANGLE_WIDTH / 2}px - ${
        props.$buttonWidth / 2
      }px) 0);`}
    ${(props) =>
      props.$green
        ? `background-image: linear-gradient(
          to left,
          ${colors.tagLemonGreen},
          ${colors.brandGreen});`
        : undefined}
  }
`;

export const TooltipBox = styled.div<{
  $right: number;
  $width: number;
  $green: boolean;
}>`
  position: absolute;
  transform: translate3d(0, -100%, 0);
  top: -20px;
  right: ${(props) => props.$right}px;
  padding: 20px 20px 27px;
  width: ${(props) => props.$width}px;
  background-color: white;
  background-image: linear-gradient(
    to left,
    ${colors.skyBlue},
    ${colors.hoverBlue}
  );
  background-size: 100% 7px;
  background-repeat: no-repeat;
  background-position: left bottom;
  box-shadow: 0 5px 12px 2px rgb(0 0 0 / 15%);
  border-radius: 8px;
  color: ${colors.lightContentGrey};
  font-size: 14px;
  text-align: left;
  ${(props) =>
    props.$green
      ? `background-image: linear-gradient(
          to left,
          ${colors.tagLemonGreen},
          ${colors.brandGreen});`
      : undefined}
`;

export const Button = styled(
  forwardRef(
    (
      {
        component: Component,
        ...props
      }: {
        className?: string;
        type?: string;
        tabIndex?: number | string;
        onClick?: MouseEventHandler;
        disabled?: boolean;
        children: ReactNode;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: ComponentType<any> | string;
        triangleColor?: string;
        'data-qa-id'?: string;
      },
      ref,
    ) => (
      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
      <Component ref={ref as any} {...props} />
    ),
  ),
)<{ $lightColor: boolean }>`
  display: block;
  padding: 0;
  overflow: visible;
  appearance: none;
  border: 0;
  outline: 0;
  background: transparent;
  position: relative;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    ${TooltipContainer} {
      opacity: 1;
      visibility: visible;
    }
    ${DefaultButton} {
      color: ${(props) =>
        props.$lightColor ? colors.brandBlue : colors.white};
      background-color: ${(props) =>
        props.$lightColor ? colors.white : colors.brandBlue};
      box-shadow: ${(props) =>
        props.$lightColor
          ? `0 0 0 1px ${colors.white} inset`
          : `0 0 0 1px ${colors.brandBlue} inset`};
    }
    ${IconButton} {
      color: ${colors.hoverBlue};
    }
  }
`;
