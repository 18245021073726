import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { colors } from 'theme/theme';

const TagContainer = styled.div`
  display: flex;
`;

export const TagSimple = styled.div<{
  $bg: string;
  $borderColor: string;
  $color: string;
  $textTransform?: string;
  $hasAnnex?: boolean;
  $thickLeftBorder?: boolean;
}>`
  align-items: center;
  display: inline-flex;
  gap: 4px;
  padding: 2px 5px;
  border-radius: ${(props) => (props.$hasAnnex ? '4px 0 0 4px' : '4px')};
  letter-spacing: 0.3px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${(props) => props.$borderColor};
  border-left-width: ${(props) => (props.$thickLeftBorder ? '5px' : '1px')};
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bg};
  text-transform: ${(props) => props.$textTransform};
`;

const Annex = styled.div<{
  $borderColor: string;
}>`
  padding: 2px 5px;
  border-radius: 0 4px 4px 0;
  letter-spacing: 0.3px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${(props) => props.$borderColor};
  color: ${colors.mediumContentGrey};
  background-color: white;
`;

type TagCombinedProps = {
  annex?: string;
  iconName?: IconName;
  $bg: string;
  $color: string;
  $borderColor?: string;
  $thickLeftBorder?: boolean;
};

export function TagCombined({
  children,
  annex,
  iconName,
  $bg,
  $color,
  $borderColor,
  $thickLeftBorder,
}: PropsWithChildren<TagCombinedProps>) {
  return (
    <TagContainer>
      <TagSimple
        $color={$color}
        $bg={$bg}
        $borderColor={$borderColor || $bg}
        $hasAnnex={Boolean(annex)}
        $thickLeftBorder={$thickLeftBorder}
      >
        {iconName && <Icon name={iconName} size={13} />}
        {children}
      </TagSimple>
      {annex && <Annex $borderColor={$borderColor || $bg}>{annex}</Annex>}
    </TagContainer>
  );
}
