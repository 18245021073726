import { useCallback, useState } from 'react';

import { SearchType } from 'modules/search/types/SearchType';
import {
  useUserDashboardListingIsSaved,
  userDashboardSaveListing,
  userDashboardUnsaveListing,
} from 'modules/userDashboard/zustand-stores/userDashboardSavedListingsStore';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { LISTING_TYPE_HUMAN_NAME_MAP } from 'utils/constants/general/listingTypeHumanNameMap';
import {
  deferredWithRouteLoadingAnimation,
  stopRouteLoadingAnimation,
} from 'utils/ui/routeLoadingAnimation';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

export type SaveListingType = (
  e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
) => Promise<void>;

type UseSaveListingProps = {
  listingType: SearchType | 'IMPORTED';
  listingName: string;
  listingId: string;
  orgId?: string | null;
  orgName?: string | null;
  orgType?: string | null;
};

export const useSaveListing = ({
  listingType,
  listingName,
  listingId,
  orgId,
  orgName,
  orgType,
}: UseSaveListingProps) => {
  const [showOrgModal, setShowOrgModal] = useState(false);
  const { user, isUserLoaded } = useUserEnvironment();

  const loggedIn = Boolean(user);
  const { listingIsSaved } = useUserDashboardListingIsSaved(listingId);
  const { listingIsSaved: orgIsSaved } = useUserDashboardListingIsSaved(orgId);

  const saveOrg = useCallback(async () => {
    if (orgId) await userDashboardSaveListing(orgId, 'ORG');
  }, [orgId]);

  const save: SaveListingType = useCallback(async () => {
    if (loggedIn) {
      await userDashboardSaveListing(listingId, listingType);

      if (
        !orgIsSaved &&
        orgId &&
        orgName &&
        listingType &&
        ['JOB', 'VOLOP', 'INTERNSHIP'].includes(listingType)
      ) {
        setShowOrgModal(true);
      }
    } else {
      showModal('EMAIL_SIGNUP', {
        title: `Save this ${LISTING_TYPE_HUMAN_NAME_MAP[listingType]}`,
        savedListing: {
          id: listingId,
          type: listingType,
          name: listingName,
        },
        redirectTo: `${window.location.pathname}${window.location.search}`,
      });
    }

    trackClicked('Save Listing', {
      listing_type: LISTING_TYPE_HUMAN_NAME_MAP[listingType],
      listing_id: listingId,
      org_id: orgId,
      org_type: orgType,
    });
  }, [
    loggedIn,
    listingType,
    listingId,
    orgId,
    orgType,
    orgIsSaved,
    orgName,
    listingName,
  ]);

  const unsave = useCallback(async () => {
    await userDashboardUnsaveListing(listingId, listingType);
    stopRouteLoadingAnimation();
  }, [listingId, listingType]);

  return {
    listingIsSaved,
    saveOrg: deferredWithRouteLoadingAnimation(saveOrg),
    setShowOrgModal,
    showOrgModal,
    canSave: isUserLoaded && listingType !== 'IMPORTED',
    saveListing: deferredWithRouteLoadingAnimation(save),
    unsaveListing: deferredWithRouteLoadingAnimation(unsave),
  };
};
